import $ from 'jquery';
import jQuery from 'jquery';
import 'what-input';
import AOS from 'aos';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.$ = $;
window.jQuery = jQuery;
// require('foundation-sites');
require('./lib/fancybox');
require('./lib/jquery.form');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

// function checkOffset() {
//   if($('.callback').offset().top + $('.callback').height() 
//                                          >= $('#footer').offset().top)
//       $('.callback').css('position', 'absolute');
//   if($(document).scrollTop() + window.innerHeight < $('#footer').offset().top)
//       $('.callback').css('position', 'fixed'); // restore when you scroll up
// }

// $(document).scroll(function(){
//   checkOffset();
// });

$(document).foundation().ready(function(){

  AOS.init({
    once: true,
  });
  
  let hamburger = $('.hamburger');
  let overlay = $('.js-off-canvas-overlay');

  let schuldTrigger = $('.call-to-action');
  let schuld = $('.schuldfrage');
  let schuldCloser = $('.schuldfrage .closer');

  if (localStorage.getItem('schuldstate')!='1') {
    schuld.removeClass('hidden').addClass('isOpen');
  } else {
    schuld.removeClass('isOpen').addClass('hidden');
  }

  hamburger.on('click', function(){
    $(this).toggleClass('isOpen');
    schuldTrigger.toggleClass('hidden');
  });

  overlay.on('click', function(){
    $('.hamburger').removeClass('isOpen');
    schuldTrigger.removeClass('hidden');
  });

  schuldTrigger.on('click', function(){
    schuld.removeClass('hidden').addClass('isOpen');
  });

  schuldCloser.on('click', function(){
    schuld.removeClass('isOpen').addClass('hidden');
    localStorage.setItem('schuldstate','1')
  });

  let scroller = $('a.scroll');

  scroller.on('click', function(){
    $('#offCanvas').removeClass('is-open');
    $('#offCanvas').addClass('is-closed');
    hamburger.removeClass('isOpen');
    overlay.removeClass('is-visible is-closable');
  });

  // let sf_parent = $('.sf_parent > a');

  // sf_parent.on('click', function(e){
  //   e.preventDefault();
  //   console.log('x');
  //   var sf_title = $(this).data('title');
  //   $('.js-title').html(sf_title);
  // });

});
